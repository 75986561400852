import { WPNewsletter } from 'Shared/newsletter';
import { trackLinkInPiwikAndGA } from 'Shared/Analytics/CombinedGAPiwik';
import { querySelectorAllWithShadowed } from 'Shared/utils/shadow-wrapper';

onDomReady(() => {
   querySelectorAllWithShadowed('.newsletter-sub').forEach(newsLetterEL => {
      new WPNewsletter(newsLetterEL, App.wpSlug);
   });

   querySelectorAllWithShadowed('.js-product-ad').forEach(el => {
      trackLinkInPiwikAndGA(el, {
         eventCategory: 'Featured Products - WP Pages',
         eventAction: `Featured on WP Pages - ${App.postTitle} - ${App.postid} - ${el.get(
            'data-ga-product'
         )}`,
      });
   });
});
